import { Controller } from "stimulus";
import Chocolat from "chocolat";

export default class extends Controller {
  static targets = ["imageLink"];

  connect() {
    this.chocolate = Chocolat(this.element.querySelectorAll('[data-lightbox-target="imageLink"]'), {
      allowZoom: false,
      fullScreen: false
    })
  }

  disconnect() {
    this.chocolate = null;
  }
}
