import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['toggle'];

  connect() {
    if( this.hasToggleTarget ) {
      this.toggleTarget.checked = localStorage.getItem("theme") == "light"
      this.setTheme( localStorage.getItem("theme") );
    }
  }

  toggle( e ) {
    let theme = e.target.checked ? 'light' : 'orange'
    localStorage.setItem("theme", theme);
    this.setTheme( theme );
  }

  setTheme( theme ) {
    if( theme == "light" )
      this.element.classList.add('theme--light');
    else
      this.element.classList.remove('theme--light');
  }


}