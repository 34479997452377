import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["background", "popup"];

  connect() {
    if (sessionStorage.getItem("corona_popup") !== "seen") {
      this.backgroundTarget.classList.add("visible");
      this.popupTarget.classList.add("visible");
      sessionStorage.setItem("corona_popup", "seen");
    }
  }

  close(e) {
    e.preventDefault();

    this.backgroundTarget.classList.remove("visible");
    this.popupTarget.classList.remove("visible");
  }
}
