import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['navbar'];


  connect() {
    document.addEventListener('scroll', this.onScroll.bind(this), { //wheel
      capture: false, passive: true
    });
  }

  onScroll(evt) {
    if( window.scrollY > 300 ) {
      this.navbarTarget.classList.add('scrolled');
    } else {
      this.navbarTarget.classList.remove('scrolled');
    }
  }


  scrollToAnchor(selector) {
    if(selector.currentTarget) { // Triggered by click listener
      let event = selector;
      selector = event.currentTarget.dataset.anchor;
      //if(window.location.pathname == "/")
      event.preventDefault(); // Prevent page refresh
    }
    const anchor = document.querySelector( selector );
    if( anchor )
      anchor.scrollIntoView({ behavior: 'smooth' });
    // Add Anchor to URL without refresh
    window.history.pushState("", selector, selector);
  }


}