import { Controller } from "stimulus";
import flatpickr from "flatpickr"
const german = require("flatpickr/dist/l10n/de.js").default.de

// Connects to data-controller="datepicker"
export default class extends Controller {

  connect() {
    this.datepicker = flatpickr(this.element, {
      altInput: true,
      altFormat: "d.m.Y",
      dateFormat: "Y-m-d",
      // defaultDate: Date.now(),
      enableTime: false,
      locale: german,
    });
  }

  disconnect() {
    this.datepicker.destroy();
    this.datepicker = null;
  }
}